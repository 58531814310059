<template>
	<div class="md-layout-item md-small-size-100 md-size-100">
		<form @submit.prevent="reset">

			<!-- Email -->
			<md-field :class="form.getFieldClass('email')">
				<label for="email">{{ trans('fields.email') }}</label>
				<md-input
					type="email"
					id="email"
					v-model="form.email"
					autocomplete="off"
					:disabled="form.loading"
				/>
				<has-error :form="form" field="email"/>
			</md-field>

			<!-- Password -->
			<md-field :class="form.getFieldClass('password')">
				<label for="password">{{ trans('fields.password') }}</label>
				<md-input
					type="password"
					id="password"
					v-model="form.password"
					:disabled="form.loading"
				/>
				<has-error :form="form" field="password"/>
			</md-field>

			<!-- Password Confirmation -->
			<md-field :class="form.getFieldClass('password_confirmation')">
				<label for="password_confirmation">{{ trans('fields.password_confirmation') }}</label>
				<md-input
					type="password"
					id="password_confirmation"
					v-model="form.password_confirmation"
					:disabled="form.loading"
				/>
				<has-error :form="form" field="password_confirmation"/>
			</md-field>

			<div class="form-group">
				<md-button type="submit" class="btn btn-primary btn-block md-primary" id="btnReset" :disabled="form.loading">
					<span v-show="form.loading">{{ trans('common.procesing') }}</span>
					<span v-show="!form.loading">{{ trans('common.reset') }}</span>
				</md-button>
			</div>
		</form>
	</div>
</template>

<script>
	import {api} from "../../../../config";
	import Form from "../../../../plugins/Form/Form";

	export default {
		data() {
			return {
				form: new Form({
					token: this.$route.params.token,
					email: null,
					password: null,
					password_confirmation: null
				})
			}
		},
		methods: {
			async reset() {
				try {
					const { data } = await this.form.post(api.reset);
					this.$noty.success(this.trans('passwords.reset'));
					this.$emit('resetSuccess', data);				
				} catch(err) {
					if (err.response.data.error) {
						this.$noty.error(this.trans('common.'+err.response.data.error));
					}

					if (err.response.status == 400) {
						//this.$noty.error(err.response.data.email); Can not be used, gives info about whether the email is registered or not
						this.$noty.error(this.trans('common.error.reset_password'))
						this.$emit('resetSuccess', {});
					}
				}
			},
		}
	}
</script>
