import Login from './components/pages/auth/Login.vue';
import Logout from './components/pages/auth/Logout.vue';
import RememberPassword from './components/pages/auth/password/RememberPassword.vue';
import ResetPassword from './components/pages/auth/password/ResetPassword.vue';
import DashboardLayout from './components/pages/Layout/DashboardLayout.vue';
import Dashboard from './components/pages/Dashboard.vue';
import Notifications from './components/pages/notifications/List.vue';
import Notification from './components/pages/notifications/Form.vue';
import UserNotifications from './components/pages/userNotifications/List.vue';
import UserNotification from './components/pages/userNotifications/Form.vue';
import Groups from './components/pages/groups/List.vue';
import Group from './components/pages/groups/Form.vue';

export default [
	{
		path: '/',
		name: 'index',
		component: DashboardLayout,
    	redirect: '/dashboard',
		meta: {requiresAuth: true},
		children: [
			{
				path: 'dashboard',
				name: 'dashboard',
				component: Dashboard,
				meta: {requiresAuth: true},
			},
			{
				path: 'groups',
				name: 'groups',
				component: Groups,
				meta: {requiresAuth: true},
			},
			{
				path: 'group/:id?',
				name: 'group',
				component: Group,
				meta: {requiresAuth: true},
			},
			{
				path: 'notifications',
				name: 'notifications',
				component: Notifications,
				meta: {requiresAuth: true},
			},
			{
				path: 'notification/:id?',
				name: 'notification',
				component: Notification,
				meta: {requiresAuth: true},
			},
			{
				path: 'user-notifications',
				name: 'user-notifications',
				component: UserNotifications,
				meta: {requiresAuth: true},
			},
			{
				path: 'user-notification/:id?',
				name: 'user-notification',
				component: UserNotification,
				meta: {requiresAuth: true},
			},
		]		
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: {requiresGuest: true}
	},
	{
		path: '/logout',
		name: 'logout',
		component: Logout,
		meta: {requiresAuth: true}
	},
	{
		path: '/password/reset/:token',
		name: 'reset-password',
		component: ResetPassword,
		meta: {requiresGuest: true}
	},
	{
		path: '/password/reset',
		name: 'forgotten-password',
		component: RememberPassword,
		meta: {requiresGuest: true}
	}
]
