<template>
    <div class="md-layout-item">
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                    <md-icon>comment_bank</md-icon>
                </div>
                <h4 class="title">{{ trans('app.group') }}</h4>
            </md-card-header>
            <md-card-content>
                <form class="md-layout" v-on:submit.prevent>
                    <div class="md-layout-item md-size-100">
                        <md-field :class="group.getFieldClass('name')">
                            <label for="name">{{ trans('fields.name') }}</label>
                            <md-input id="name" v-model="group.name"/>
                            <has-error :form="group" field="name"/>
                        </md-field>
                    </div>

                    <!-- Dual Selects -->
                    <div class="md-layout-item md-size-100">
                        <DualSelects
                            :leftLabel="trans('fields.available_users')"
                            :rightLabel="trans('fields.group_users')"
                            :leftData="leftUsers"
                            :rightData="rightUsers"
                        ></DualSelects>
                        <has-error :form="group" field="users"/>
                    </div>

                    <div class="md-layout-item md-size-100 text-right">
                        <md-button class="md-primary" @click="save()" :disabled="group.loading" id="btnSave">
                            <span v-show="group.loading">{{ trans('common.saving') }}</span>
                            <span v-show="!group.loading">{{ trans('common.save') }}</span>
                        </md-button>
                    </div>
                </form>
            </md-card-content>
        </md-card>
    </div>
</template>

<script>
import { api } from "../../../config";
import Form from "../../../plugins/Form/Form";
import DualSelects from "../../Inputs/DualSelects.vue";
export default {
    components: {
        DualSelects
    },

    data() {
        return {
            requiredFields: [
                "name",
            ],
            group: new Form(),
            leftUsers: [],
            rightUsers: [],
            currentSort: 'name',
            currentSortOrder: 'asc',
            loading: false,
            txt_search: "",
        }
    },

    created() {
        this.fetchData();
    },

    methods: {
        fetchData() {
            var url = api.domain;
            if (this.$route.params.id) {
                url += "group/" + this.$route.params.id + "/edit";
            } else {
                url += "group/create";
            }
            axios.get(url).then(response => {
                this.group = new Form(response.data.group);
                this.group.requiredFields = this.requiredFields;
                this.loadDualSelects(response.data.users, response.data.group_users);
            }) .catch(function(error) {
                console.log("An error occured obtaining group info" + error);
            });
        },

        async save() {
            var response;

            try {
                if (this.group.id != null && this.group.id != '') {
                    // Edition
                    this.group.users = this.rightUsers;
                    response = await this.group.put(api.domain + 'group/'+this.group.id);
                } else {
                    // Creation
                    this.group.users = this.rightUsers;
                    response = await this.group.post(api.domain + 'group');
                }

                if (response.data.success) {
                    this.$noty.success(this.trans('common.success.saving'));
                    this.$router.push({ name: 'groups'});
                } else {
                    this.$noty.error(response.data.msge);
                }
            } catch(err) {
                if (err.response && err.response.data.error) {
                    this.$noty.error(this.trans('common.'+err.response.data.error));
                } else {
                    console.log('an error occured ' + err);
                    this.$noty.error(this.trans('common.error.saving'));
                }
            }
        },

        loadDualSelects(available_users, selected_users) {
            // Available users
            this.leftUsers = [];
            available_users.forEach(user => {
                if (!selected_users.includes(user.email))
                this.leftUsers.push(user.email);
            });
            
            // Group users
            this.rightUsers = [];
            selected_users.forEach(user => {
                this.rightUsers.push(user);
            });
        },
    },
}
</script>

<style scoped>

</style>