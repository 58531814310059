/*
|--------------------------------------------------------------------------
| Mutation Types
|--------------------------------------------------------------------------
*/
export const SET_TOKEN = 'SET_TOKEN';
export const UNSET_TOKEN = 'UNSET_TOKEN';

export const SET_USER = 'SET_USER';
export const UNSET_USER = 'UNSET_USER';

/*
|--------------------------------------------------------------------------
| Initial State
|--------------------------------------------------------------------------
*/
const initialState = {
	jwt_token: null,
	name: null,
	email: null
};

/*
|--------------------------------------------------------------------------
| Mutations
|--------------------------------------------------------------------------
*/
const mutations = {
	[SET_TOKEN](state, payload) {
		state.jwt_token = payload.access_token;
	},
	[UNSET_TOKEN](state, payload) {
		state.jwt_token = null;
	},

	[SET_USER](state, payload) {
		state.name = payload.user.name;
		state.email = payload.user.email;
	},
	[UNSET_USER](state, payload) {
		state.name = null;
		state.email = null;
	}
};

/*
|--------------------------------------------------------------------------
| Actions
|--------------------------------------------------------------------------
*/
const actions = {
	setToken: (context, access_token) => {
		context.commit(SET_TOKEN, {access_token});
	},
	removeToken: (context) => {
		context.commit(UNSET_TOKEN);
	},

	setAuthUser: (context, user) => {
		context.commit(SET_USER, {user});
	},
	unsetAuthUser: (context) => {
		context.commit(UNSET_TOKEN);
		context.commit(UNSET_USER);
	}
};

/*
|--------------------------------------------------------------------------
| Getters
|--------------------------------------------------------------------------
*/
const getters = {
	getToken: (state) => {
		return state.jwt_token;
	},

	isLoggedIn: (state) => {
		return !!(state.name && state.email);
	}
};

/*
|--------------------------------------------------------------------------
| Export the module
|--------------------------------------------------------------------------
*/
export default {
	state: initialState,
	mutations,
	actions,
	getters
}