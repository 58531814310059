const apiDomain = Laravel.apiDomain;
export const siteName = Laravel.siteName;
export const adminGroupName = Laravel.adminGroup;

export const api = {
	domain: apiDomain + '/',
	login: apiDomain + '/auth/login',
	currentUser: apiDomain + '/auth/user',
	remember: apiDomain + '/auth/password/email',
	reset: apiDomain + '/auth/password/reset',
};

export const NotificationType = Object.freeze({
    AUDIO_TYPE: 'audio',
    DIRECTO_TYPE: 'directo',
    DIRECTO_RADIO_TYPE: 'directo_radio',
    NOTICIA_TYPE: 'noticia',
    TEXTO_TYPE: 'texto',
    VIDEO_TYPE: 'video',
});

export const ScheduleType = Object.freeze({
    PUNCTUAL: 'punctual',
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
});