<template>
	<div class="content">
		<div class="md-layout">
			<h1>{{ trans('common.dashboard') }}</h1>
		</div>
	</div>
</template>

<script>
	import {siteName} from './../../config';
	import {mapGetters} from 'vuex';
	import 'vuejs-noty/dist/vuejs-noty.css'

	export default {
		data() {
			return {
				siteName: siteName
			}
		},
		computed: mapGetters([
			'isLoggedIn'
		])/*,
		methods: {
			logout() {
				this.$store.dispatch('unsetAuthUser')
					.then(() => {
						this.$noty.success(this.trans('common.logged_out'));
						this.$router.push({name: 'login'});
					});
			}
		}
		*/
	}
</script>