<template>
	<div class="container-fluid">
		<div class="row" style="padding-top: 5%;">
			<div class="md-layout-item md-size-80 offset-1">
				<md-card>
					<md-card-header class="md-card-header-icon md-card-header-primary">
						<div class="card-icon">
						    <md-icon>lock</md-icon>
						</div> 						
						<h4 class="title">{{ trans('common.password_reset') }}</h4>
					</md-card-header>

					<md-card-content>
						<div class="md-layout">
							<reset-form @resetSuccess="resetSuccess"></reset-form>
						</div>
					</md-card-content>
				</md-card>				
			</div>
		</div>
	</div>
</template>

<script>
	import ResetForm from './ResetForm.vue'

	export default {
		components: {
			'reset-form': ResetForm
		},
		methods: {
			resetSuccess(data) {
				this.$router.push({name: 'login'});
			}
		}
	}
</script>
