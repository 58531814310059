<template>
    <span class="md-error" v-if="form.errors.has(field)">{{ form.errors.get(field) }}</span>
</template>

<script>
export default {
    name: 'has-error',
    props: {
        form: {
            type: Object,
            required: true
        },
        field: {
            type: String,
            required: true
        }
    }
}
</script>