<template>
    <div class="md-layout-item">
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                    <md-icon>notifications</md-icon>
                </div>
                <h4 class="title">{{ trans('app.notification') }}</h4>
            </md-card-header>
            <md-card-content>
                <form class="md-layout" v-on:submit.prevent>
                    <div class="md-layout-item md-size-50 md-medium-size-100 md-small-size-100">
                        <md-field :class="notification.getFieldClass('title')">
                            <label for="title">{{ trans('fields.title') }}</label>
                            <md-input id="title" v-model="notification.title"/>
                            <has-error :form="notification" field="title"/>
                        </md-field>

                        <md-field :class="notification.getFieldClass('message')">
                            <label for="message">{{ trans('fields.message') }}</label>
                            <md-input id="message" v-model="notification.message"/>
                            <has-error :form="notification" field="message"/>
                        </md-field>

                        <md-field :class="notification.getFieldClass('type')">
                            <label for="type">{{ trans('fields.type') }}</label>
                            <md-select id='type' v-model='notification.type' style="overflow: hidden;" @md-selected="schedule.clear();">
                                <md-option v-for="(type, index) in types" :key='index' :value='type' >{{ type }}</md-option>
                            </md-select>
                            <has-error :form="notification" field="type"/>
                        </md-field>

                        <md-field :class="notification.getFieldClass('users')">
                            <label for="users">{{ trans('fields.users') }}</label>
                            <md-select id='users' v-model='notification.users' style="overflow: hidden;" multiple>
                                <md-option v-for="(user, index) in users" :key='index' :value='user.id' >{{ user.name }}</md-option>
                            </md-select>
                            <has-error :form="notification" field="users"/>
                        </md-field>

                        <!-- <md-field :class="notification.getFieldClass('image_url')">
                            <label for="image_url">{{ trans('fields.image_url') }}</label>
                            <md-input id="image_url" v-model="notification.image_url"/>
                            <has-error :form="notification" field="image_url"/>
                        </md-field> -->
                    </div>

                    <div class="md-layout-item md-size-30 md-medium-size-40 md-small-size-100">
                        <AndroidPreview :backgroundImage=backgroundImage textApplicationName="Guaguas Notificaciones" :textTitle="notification.title" :image="imageURL()" :textBody="notification.message" :height=440></AndroidPreview>
                    </div>

                    <div class="md-layout-item md-size-20 md-medium-size-60 md-small-size-100">
                        <p v-if="notification.sent" class="text-success">{{ trans('app.notif_sent') }}</p>
                        <p v-else class="text-danger">{{ trans('app.notif_no_sent') }}</p>

                        <p>{{ trans('app.last_sent') }}: <span v-if="notification.last_sent">{{ notification.last_sent|formatDate }}</span><span v-else>-</span></p>

                        <p v-if="notification.schedule_type">{{ trans('app.notif_scheduled') }}: {{ trans('app.schedule_types.'+notification.schedule_type) }}</p>
                        <p v-else>{{ trans('app.notif_no_scheduled') }}</p>
                    </div>

                    <div class="md-layout-item md-size-100 text-right">
                        <md-button class="md-primary" @click="showSchedule  =true;" :disabled="notification.loading || !notifExists" id="btnSchedule">{{ trans('app.schedule') }}
                        </md-button>

                        <md-button class="md-primary" @click="confirmSendNotification()" :disabled="notification.loading || sending || sendingTest || removing" id="btnSend">
                            <span v-show="sending">{{ trans('common.sending') }}</span>
                            <span v-show="!sending">
                                <span v-if="notification.sent">{{ trans('app.resend') }}</span>
                                <span v-else>{{ trans('common.send') }}</span>
                            </span>
                        </md-button>

                        <!-- <md-button class="md-primary" @click="confirmTestSendNotification()" :disabled="notification.loading || sending || sendingTest || removing" id="btnTestSend">
                            <span v-show="sendingTest">{{ trans('common.sending') }}</span>
                            <span v-show="!sendingTest">
                                {{ trans('app.send_test') }}
                            </span>
                        </md-button> -->

                        <md-button class="md-primary" @click="confirmDeleteNotification()" :disabled="notification.loading || !notifExists" id="btnRemove">
                            <span v-show="removing">{{ trans('app.removing') }}</span>
                            <span v-show="!removing">{{ trans('app.remove') }}</span>
                        </md-button>

                        <md-button class="md-primary" @click="save()" :disabled="notification.loading" id="btnSave">
                            <span v-show="notification.loading && !sending && !sendingTest && ! removing">{{ trans('common.saving') }}</span>
                            <span v-show="!notification.loading">{{ trans('common.save') }}</span>
                        </md-button>
                    </div>
                </form>
            </md-card-content>
        </md-card>

        <!-- Schedule modal form -->
        <md-dialog :md-active.sync="showSchedule">
            <md-dialog-title>{{ trans('app.programming') }}</md-dialog-title>

            <md-dialog-content md-scrollbar>
                <div class="md-layout-item md-size-100">
                    <form class="md-layout" v-on:submit.prevent="">

                        <div class="md-layout-item md-size-50 md-small-size-100">
                            <md-field :class="schedule.getFieldClass('type')">
                                <label for="type">{{ trans('fields.type') }}</label>
                                <md-select id='type' v-model='schedule.type' style="overflow: hidden;">
                                    <md-option v-for="(type, index) in schedule_types" :key='index' :value='type' >{{ trans('app.schedule_types.'+type) }}</md-option>
                                </md-select>
                                <has-error :form="schedule" field="type"/>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-size-50 md-small-size-100">
                            <div>
                            <md-radio v-model="schedule.active" :value="1">
                                <span v-if="schedule.active===1" class="text-success">{{trans('app.enabled')}}</span>
                                <span v-else>{{trans('app.enabled')}}</span>
                            </md-radio>
                            <md-radio v-model="schedule.active" :value="0">
                                <span v-if="schedule.active===0" class="text-danger">{{trans('app.disabled')}}</span>
                                <span v-else>{{trans('app.disabled')}}</span>
                            </md-radio>
                            <has-error :form="schedule" field="active"/>
                            </div>
                        </div>
                        <div v-if="schedule.type==PUNCTUAL" class="md-layout-item md-size-33 md-small-size-100">
                            <md-datepicker v-model="schedule.day" md-immediately class="schedule.getFieldClass('day')" style="margin-bottom:0;">
                                <label for="day">{{ trans('fields.day') }}</label>
                            </md-datepicker>
                            <has-error :form="schedule" field="day"/>
                        </div>
                        <div class="md-layout-item md-size-33 md-small-size-100">
                            <md-field v-show="schedule.type" :class="[schedule.getFieldClass('hour'),{ 'md-error': errors.has('hour') } ]">
                                <label for="fields.hour">{{ trans('fields.hour') }}</label>
                                <md-input id="hour" type="text" v-model="schedule.hour" :disabled="schedule.loading"></md-input>
                                <has-error :form="schedule" field="hour"/>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-33 md-small-size-100">
                            <md-field v-show="schedule.type" :class="[schedule.getFieldClass('minute'),{ 'md-error': errors.has('minute') } ]">
                                <label for="fields.minute">{{ trans('fields.minute') }}</label>
                                <md-input id="minute" type="text" v-model="schedule.minute" :disabled="schedule.loading"></md-input>
                                <has-error :form="schedule" field="minute"/>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-33 md-small-size-100" v-if="schedule.type==MONTHLY">
                            <md-field :class="[schedule.getFieldClass('day_month'),{ 'md-error': errors.has('day_month') } ]">
                                <label for="fields.day_month">{{ trans('fields.day_month') }}</label>
                                <md-input id="day_month" type="number" :min="1" :max="31" v-model="schedule.day_month" :disabled="schedule.loading"></md-input>
                                <has-error :form="schedule" field="day_month"/>
                            </md-field>
                        </div>
                        <div v-if="schedule.type==WEEKLY" class="md-layout-item md-size-100" style="margin-top:24px;">
                            <md-checkbox v-for="(weekday, index) in weekdays" :key="index" v-model="schedule.weekdays" :value="weekday.id">{{ trans('common.days.'+ weekday.code) }}</md-checkbox>
                            <br/>
                            <has-error :form="schedule" field="weekdays" style="color:red;" />
                        </div>
                    </form>
                </div>
            </md-dialog-content>

            <md-dialog-actions>
                <md-button @click="closeSchedule">{{trans('common.cancel')}}</md-button>
                <md-button class="md-primary" @click="scheduleNotif" :disabled="schedule.loading">{{trans('common.save')}}</md-button>
            </md-dialog-actions>
        </md-dialog>

        <md-dialog-confirm
            :md-active.sync="showConfirmTestSend"
            :md-title="trans('common.confirmation')"
            :md-content="confirmTestSendContent"
            :md-confirm-text="trans('common.ok')"
            :md-cancel-text="trans('common.cancel')"
            @md-confirm="sendNotification(true)" />

        <md-dialog-confirm
            :md-active.sync="showConfirmSend"
            :md-title="trans('common.confirmation')"
            :md-content="confirmSendContent"
            :md-confirm-text="trans('common.ok')"
            :md-cancel-text="trans('common.cancel')"
            @md-confirm="sendNotification" />

        <md-dialog-confirm
            :md-active.sync="showConfirmDelete"
            :md-title="trans('common.confirmation')"
            :md-content="confirmDeleteContent"
            :md-confirm-text="trans('common.ok')"
            :md-cancel-text="trans('common.cancel')"
            @md-confirm="deleteNotification" />
    </div>
</template>

<script>
import { api, NotificationType, ScheduleType } from "../../../config";
import { AndroidPreview, IphonePreview } from 'vue-push-notification-preview';
import Form from "../../../plugins/Form/Form";

export default {
    components: {
        AndroidPreview,
        IphonePreview
    },

    mounted() {
        this.fetchData();
    },

    data() {
        return {
            backgroundImage: '/img/fondoAndroid.jpg',
            requiredFields: [
                "title",
                "message",
                "type",
                "rtve_id",
                "topic_id",
            ],
            scheduleRequiredFields: [
                "type",
                "day",
                "hour",
                "minute",
                "weekdays",
                "day_month"
            ],
            types: [],
            users: [],
            schedule_types: [],
            weekdays: [],
            notifExists: false,
            notification: new Form(),
            schedule: new Form({
                type: ScheduleType.PUNCTUAL,
                active: 0,
            }),
            currentSort: 'name',
            currentSortOrder: 'asc',
            loading: false,
            sending: false,
            sendingTest: false,
            removing: false,
            txt_search: "",
            showConfirmSend: false,
            confirmSendContent: this.trans('app.confirm.send_notification', {notification: ''}),
            showConfirmTestSend: false,
            confirmTestSendContent: this.trans('app.confirm.send_test', {notification: ''}),
            showConfirmDelete: false,
            confirmDeleteContent: this.trans('app.confirm.delete_notification', {notification: ''}),
            showSchedule: false,
            PUNCTUAL: ScheduleType.PUNCTUAL,
            WEEKLY: ScheduleType.WEEKLY,
            MONTHLY: ScheduleType.MONTHLY,
        }
    },

    methods: {
        fetchData() {
            var url = api.domain;
            if (this.$route.params.id) {
                url += "usernotification/" + this.$route.params.id + "/edit";
            } else {
                url += "usernotification/create";
            }
            axios.get(url).then(response => {
                this.notification = new Form(response.data.notification);
                this.setNotificationUsers(response.data.notification);                
                if (this.notification.hasOwnProperty('id')) {
                    this.notifExists = this.notification.id;
                }
                if (response.data.notification.schedule) {
                    this.schedule = new Form(response.data.notification.schedule);
                    this.schedule.weekdays = _.map(response.data.notification.schedule.weekdays, _.property('id'));
                }
                this.notification.requiredFields = this.requiredFields;
                this.schedule.requiredFields = this.scheduleRequiredFields;
                this.sending = false;
                this.sendingTest = false;
                this.removing = false;
                this.types = response.data.types;
                this.users = response.data.users;
                this.schedule_types = response.data.schedule_types;
                this.weekdays = response.data.weekdays;
            }) .catch(function(error) {
                console.error("An error occured obtaining notification info: ", error);
            });
        },

        setNotificationUsers(notification) {
            if (notification.users) {
                this.notification.users = [];
                notification.users.forEach(user => {
                    this.notification.users.push(user.id);
                });
            }
        },

        async save() {
            var response;

            try {
                if (this.notification.id != null && this.notification.id != '') {
                    // Edition
                    response = await this.notification.put(api.domain + 'usernotification/' + this.notification.id);
                } else {
                    // Creation
                    response = await this.notification.post(api.domain + 'usernotification');
                }

                if (response.data.success) {
                    if (response.data.hasOwnProperty('notification_id')) {
                        this.notification.id = response.data.notification_id;
                        this.notifExists = true;
                    }
                    this.$noty.success(this.trans('common.success.saving'));
                    //this.$router.push({ name: 'notifications', params: { appId: this.$route.params.appId }});
                } else {
                    this.$noty.error(response.data.msge);
                }
            } catch(err) {
                if (err.response && err.response.data.error) {
                    this.$noty.error(this.trans('common.'+err.response.data.error));
                } else {
                    console.log('an error occured ' + err);
                    this.$noty.error(this.trans('common.error.saving'));
                }
            }
        },

        confirmTestSendNotification() {
            this.showConfirmTestSend = true;
        },

        confirmSendNotification() {
            this.showConfirmSend = true;
        },

        async sendNotification(test = false) {
            if (test) {
                this.sendingTest = true;
            } else {
                this.sending = true;
            }

            var response;
            try {
                var action = '/send';
                if (test) {
                    action = '/test';
                }
                response = await this.notification.post(api.domain + 'usernotification/' + this.notification.id + action);

                if (response.data.success) {
                    this.notification = new Form(response.data.notification);
                    this.setNotificationUsers(response.data.notification);
                    this.$noty.success(this.trans('app.success.send_notif'));
                    this.$router.push({name: 'user-notifications'});
                } else {
                    if (response.data.msge) {
                        this.$noty.error(response.data.msge);
                    } else {
                        this.$noty.error(this.trans('app.error.send_notif'));
                    }
                }
                this.sending = false;
                this.sendingTest = false;
            } catch(err) {
                this.sending = false;
                this.sendingTest = false;
                if (err.response && err.response.data.error) {
                    this.$noty.error(this.trans('common.'+err.response.data.error));
                } else {
                    console.log('an error occured ' + err);
                    this.$noty.error(this.trans('app.error.send_notif'));
                }
            }
        },

        confirmDeleteNotification() {
            this.showConfirmDelete = true;
        },

        deleteNotification() {
            let uri = api.domain + 'userusernotification/' + this.$route.params.id;
            this.removing = true;
            axios.delete(uri).then((response) => {
                this.$noty.success(this.trans('app.success.delete_notif'));
                this.$router.push({ name: 'notifications', params: { appId: this.$route.params.appId }});
            }).catch(error => {
                // handle error
                this.removing = false;
                if (error.response.status == 400) {
                    console.log('an error occured ' + error.response.data.message);
                } else {
                    console.log('an error occured ' + error);
                }
            });
        },

        imageURL() {
            var imageURL = this.notification.imageURL;

            if (!imageURL || imageURL == null) {
                imageURL = '';
            }

            return imageURL;
        },

        async closeSchedule() {
            await this.fetchData();
            var url = api.domain + "notification/" + this.$route.params.id + "/schedule";
            axios.get(url).then(response => {
                this.showSchedule = false;

                if (response.status == 200) {
                    let schedule = response.data;
                    this.notification.schedule = schedule;
                    this.schedule = new Form(schedule);
                } else {
                    this.notification.schedule = null;
                    this.schedule = new Form({
                        type: ScheduleType.PUNCTUAL,
                        active: 0,
                    });
                }
                this.schedule.requiredFields = this.scheduleRequiredFields;
            }) .catch(function(error) {
                console.error("An error occured obtaining schedule info: ", error);
            });
        },

        async scheduleNotif() {
            var response;

            try {
                if (this.schedule.id != null && this.schedule.id != '') {
                    // Edition
                    response = await this.schedule.put(api.domain + 'schedule/' + this.schedule.id);
                } else {
                    // Creation
                    response = await this.schedule.post(api.domain + 'notification/' + this.$route.params.id + '/schedule/usernotification');
                }
                console.log("response scheduleNotif", response);

                if (response.data.success) {
                    this.$noty.success(this.trans('common.success.saving'));
                    this.closeSchedule();
                } else {
                    this.$noty.error(response.data.msge);
                }
            } catch(err) {
                if (err.response && err.response.data.error) {
                    this.$noty.error(this.trans('common.'+err.response.data.error));
                } else {
                    console.log('an error occured ' + err);
                    this.$noty.error(this.trans('common.error.saving'));
                }
            }
        },
    },
}
</script>

<style scoped>

</style>