<template>

</template>

<script>
    export default {
        mounted () {
			this.$store.dispatch('unsetAuthUser')
				.then(() => {
					this.$noty.success(this.trans('common.logged_out'));
					this.$router.push({name: 'login'});
				});
        }
    }
</script>