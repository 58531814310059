<template>
    <div class="dual-selects">
        <div>
            <h2>{{ leftLabel }}</h2>
            <select multiple v-model="leftSelectedData" @dblclick="moveRight">
                <option v-for="item in leftData">{{ item }}</option>
            </select>
        </div>

        <div class="middle">
            <button @click="moveRight">=&gt;</button>
            <button @click="moveLeft">&lt;=</button>
        </div>

        <div>
            <h2>{{ rightLabel }}</h2>
            <select multiple v-model="rightSelectedData" @dblclick="moveLeft">
                <option v-for="item in rightData">{{ item }}</option>
            </select>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            leftSelectedData: [],
            rightSelectedData: []
        };
    },
    props: {
        leftLabel: {
            type: String,
            required: true
        },
        rightLabel: {
            type: String,
            required: true
        },
        leftData: {
            type: Array,
            required: true
        },
        rightData: {
            type: Array,
            required: true
        }
    },
    methods: {
        moveLeft() {
            if (!this.rightSelectedData.length) return;
            for (let i = this.rightSelectedData.length; i > 0; i--) {
                let idx = this.rightData.indexOf(this.rightSelectedData[i - 1]);
                this.rightData.splice(idx, 1);
                this.leftData.push(this.rightSelectedData[i - 1]);
                this.rightSelectedData.pop();
            }
        },
        moveRight() {
            if (!this.leftSelectedData.length) return;
            for (let i = this.leftSelectedData.length; i > 0; i--) {
                let idx = this.leftData.indexOf(this.leftSelectedData[i - 1]);
                this.leftData.splice(idx, 1);
                this.rightData.push(this.leftSelectedData[i - 1]);
                this.leftSelectedData.pop();
            }
        }
    }
};
</script>

<style scoped>
.dual-selects {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  margin-top: 60px;
}

.dual-selects {
  display: grid;
  grid-template-columns: 30% 10% 30%;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
}

.dual-selects h2 {
    font-size: 1.25rem;
}

.dual-selects select {
  height: 200px;
  width: 100%;
}

.dual-selects .middle {
  text-align: center;
}

.dual-selects button {
  width: 80%;
  margin-bottom: 5px;
}
</style>